import { Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';

import { isEmptyOrUndefined, MultiSelector, Selector } from "@zippeditoolsjs/blocks";

import { LocalStorageService } from 'core/services/localStorage';
import { ZIPPEDI_CLIENT_ID } from "core/utils/constants";
import AlertBox from '../../tools/AlertBox';
import DataTable from "./DataTable";

export default function LastProductState(props) {
  const {
    isLoadingUserStores,
    isLoadingSuppliers,
    isLoadingCategories,
    selectedClient,
    userChains,
    suppliers,
    userStores,
    categories,
    getStoreCategories,
    getUserStores,
    getUserSuppliers,
    getProductsStates,
    isLoadingProductsStates,
    productsStates,
    getStockCategories,
    isLoadingStockCategories,
    stockCategories,
    isLoadingLastProductSources,
    lastProductSources,
    getLastProductSources,
  } = props;

  const { t } = useTranslation();
  //TODO - Remove this mock and create a table in cube to get all posibles status for products
  const statusProducts = [
    { 'status_name': t('cws_app.general.Product_available', 'Product Available'), 'status_id': true },
    { 'status_name': t('cws_app.general.Product_not_available', 'Product Not Available'), 'status_id': false },
  ];

  // Filters states
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [filteredStores, setFilteredStores] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStockCategories, setSelectedStockCategories] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  // Table states
  const [isAllFilterSelected, setIsAllFilterSelected] = useState(true);

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedClient, 'object')) {
      getUserStores(selectedClient.client_id);
      setSelectedChain(null);
      setSelectedStore(null);
    }
  }, [selectedClient]);

  useEffect(() => {
    getStockCategories(t);
    getLastProductSources(t);
  }, []);

  // Filter the stores list based on the selected chain. If there's no chain selected, show all the stores
  useEffect(() => {
    const selectedChainID = selectedChain?.chain_id ?? userChains[0]?.chain_id;
    if (!isEmptyOrUndefined(selectedChain, 'object')) {
      const filteredStores = userStores.filter(store => store.chain_id === selectedChainID);
      setFilteredStores(filteredStores);
    } else {
      setFilteredStores(userStores);
    }
  }, [selectedChain, userChains])

  // Get categories
  useEffect(() => {
    if (!isEmptyOrUndefined(selectedStore, 'object') && selectedStore?.store_id !== null) {
      // Gets the categories only from the first store in the list
      getStoreCategories(selectedStore.store_id, selectedClient.client_id, true);
    }
  }, [selectedStore])

  useEffect(() => {
    if (!isEmptyOrUndefined(selectedChain, 'object')) {
      setSelectedSuppliers([]);
      getUserSuppliers(selectedClient.client_id, [selectedChain?.chain_name]);
    }
  }, [selectedChain]);

  useEffect(() => {
    if (selectedChain !== null
      && !isEmptyOrUndefined(selectedStore, 'object')) {

      setIsAllFilterSelected(true);
      const selectedSupplierId = selectedClient.supplier_id ? [selectedClient.supplier_id] : selectedSuppliers.map(item => item.id);
      getProductsStates(selectedStore.store_code, selectedSupplierId, selectedCategories, selectedStatus, selectedDataSource, selectedStockCategories)
    }
    else {
      setIsAllFilterSelected(false)
    }
  }, [selectedChain, selectedStore, selectedSuppliers, selectedCategories, selectedStatus, selectedStockCategories, selectedDataSource]);

  useEffect(() => {
    if (selectedClient) {
      getUserStores(selectedClient);
      getStoreCategories(selectedClient);
      getUserSuppliers(selectedClient);
      getStockCategories(t);
      getLastProductSources(t);
    }
  }, [selectedClient]);

  const getFromLocalStorage = () => {
    const localStorageService = new LocalStorageService();
    const localStores = localStorageService.get(localStorageService.paths.home_store);
    let localStoresFiltered = [];
    if (!isEmptyOrUndefined(localStores, 'array') && !isEmptyOrUndefined(selectedClient, 'object')) {
      localStoresFiltered = localStores.filter(store => (store.chain_name === selectedClient?.name) || selectedClient?.client_id === ZIPPEDI_CLIENT_ID);
    }
    return localStoresFiltered;
  }

  return (
    <Grid container pt={4} px={2}>
      <Grid container spacing={2}>
        {/* Title */}
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            <Trans>cws_app.general.last_product_state_title</Trans>
          </Typography>
        </Grid>
      </Grid>
      {/* Filters */}
      <Grid container spacing={1}>
        {/* Chain */}
        <Grid item xs={4} md={4} sx={{ flexGrow: 1 }}>
          <Selector
            disabled={userChains?.length <= 0}
            options={userChains}
            value={selectedChain}
            setValue={setSelectedChain}
            label={t('cws_app.general.sel_chain', 'Chain')}
            name='chain_name'
            loading={isLoadingUserStores}
          />
        </Grid>
        {/* Stores */}
        <Grid item xs={4} md={4} sx={{ flexGrow: 1 }}>
          <Selector
            disabled={filteredStores?.length <= 0 || !selectedChain}
            options={filteredStores}
            value={selectedStore !== null ? selectedStore : null}
            setValue={setSelectedStore}
            label={t('cws_app.general.Stores', 'Stores')}
            name='parsed_name'
            loading={isLoadingUserStores}
          />
        </Grid>
        {isEmptyOrUndefined(selectedClient?.supplier_id, 'id') &&
          <Grid item xs={4} md={4} sx={{ flexGrow: 1 }}>
            {/* Suppliers */}
            <MultiSelector
              disabled={selectedChain === null}
              options={suppliers}
              value={selectedSuppliers}
              setValue={setSelectedSuppliers}
              label={t('cws_app.general.Supplier', 'Supplier')}
              name='name'
              loading={isLoadingSuppliers}
              limitTags={1}
            />
          </Grid>
        }

        <Grid item xs={4} md={3} sx={{ flexGrow: 1 }}>
          {/* Categories */}
          <MultiSelector
            disabled={categories?.categories?.length <= 0}
            options={categories?.categories}
            value={selectedCategories}
            setValue={setSelectedCategories}
            label={t('cws_app.general.categories', 'Categories')}
            name='category_name'
            loading={isLoadingCategories}
            limitTags={1}
          />
        </Grid>
        {/* statusProducts */}
        <Grid item xs={4} md={3} sx={{ flexGrow: 1 }}>
          <MultiSelector
            options={statusProducts}
            value={selectedStatus}
            setValue={setSelectedStatus}
            label={t('cws_app.general.Select_Product_Status', 'Status')}
            name='status_name'
            loading={isLoadingCategories}
            limitTags={1}
          />
        </Grid>
        {/* stockCategories */}
        <Grid item xs={4} md={3} sx={{ flexGrow: 1 }}>
          <MultiSelector
            options={stockCategories}
            value={selectedStockCategories}
            setValue={setSelectedStockCategories}
            label={t('cws_app.general.Select_stock_category', 'Stock Category')}
            name='stock_name'
            loading={isLoadingStockCategories}
            limitTags={1}
          />
        </Grid>
        {/* Data Source */}
        <Grid item xs={4} md={3} sx={{ flexGrow: 1 }}>
          <MultiSelector
            options={lastProductSources}
            value={selectedDataSource}
            setValue={setSelectedDataSource}
            label={t('cws_app.general.Source', 'Source')}
            name='source_name'
            loading={isLoadingLastProductSources}
            limitTags={1}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        {/* Table */}
        {
          isAllFilterSelected ?
            isLoadingProductsStates ?
              <LinearProgress sx={{ width: '100%', my: 2 }} color="secondary" />
              :
              isEmptyOrUndefined(productsStates, 'array') ?
                <Paper component={Grid} container item justifyContent='center'>
                  {/* No data to display */}
                  <AlertBox
                    content={t('cws_app.general.No_data', 'No data to display.')}
                  />
                </Paper>
                :
                <DataTable
                  productsStates={productsStates}
                  isLoadingProductsStates={isLoadingProductsStates}
                  t={t}
                />
            :
            <Grid item xs={4} sm={8} md={12}>
              {/*No Filter Selected message*/}
              <Paper sx={{ p: 2 }}>
                <Typography textAlign={'center'}>{t('cws_app.general.select_information_to_display', 'Select Information to Display')}</Typography>
              </Paper>
            </Grid>
        }

      </Grid>
    </Grid>
  )
}

