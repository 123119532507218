import { createReducer } from "./utils";

import {
    handleAvailable,
    handleRequest,
    handleUnavailable
} from '../reducerHandlers';

import {
    PRODUCTS_STATES_AVAILABLE,
    PRODUCTS_STATES_REQUEST,
    PRODUCTS_STATES_UNAVAILABLE,
    SNACK_SET,
} from './constants';

const defaultState = {
    productsStates: [],
    loadingProductsStates: false,
    stockCategories: [],
    loadingStockCategories: false,
    lastProductSources: [],
    loadingLastProductSources: false,
}

export const reducer = createReducer(defaultState, {
    [PRODUCTS_STATES_REQUEST]: handleRequest,
    [PRODUCTS_STATES_AVAILABLE]: handleAvailable,
    [PRODUCTS_STATES_UNAVAILABLE]: handleUnavailable,
});

export function getProductsStates(store, supplier_ids, categories, products_status = [], availability_source = [], stock_categories = []) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingProductsStates'
        const keyState = 'productsStates'

        dispatch({ type: PRODUCTS_STATES_REQUEST, payload: { loadingName } });
        try {
            const productsStatesResponse = await dataSource.getProductsStatus(store, supplier_ids, categories, products_status, stock_categories, availability_source);
            const productsState = productsStatesResponse?.data ?? [];
            dispatch({
                type: PRODUCTS_STATES_AVAILABLE,
                payload: { data: productsState, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: PRODUCTS_STATES_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the products states',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getStockCategories(t) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingStockCategories'
        const keyState = 'stockCategories'

        dispatch({ type: PRODUCTS_STATES_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getStockCategories();
            const stockCategories = (response?.stock_categories ?? []).map(category => ({
                stock_name: t(`cws_app.general.${category.key_name}`, category.default_key_name),
                stock_cube_id: category.stock_cube_id
            }));
            dispatch({
                type: PRODUCTS_STATES_AVAILABLE,
                payload: { data: stockCategories, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: PRODUCTS_STATES_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the stock categories',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getLastProductSources(t) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingLastProductSources'
        const keyState = 'lastProductSources'

        dispatch({ type: PRODUCTS_STATES_REQUEST, payload: { loadingName } });
        try {
            const response = await dataSource.getLastProductSources();
            const lastProductSources = (response?.data_sources ?? []).map(source => ({
                source_name: t(`cws_app.general.${source.key_name}`, source.default_key_name),
                source_cube_id: source.source_cube_id
            }));
            dispatch({
                type: PRODUCTS_STATES_AVAILABLE,
                payload: { data: lastProductSources, keyState, loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: PRODUCTS_STATES_UNAVAILABLE,
                payload: { error, keyState, loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error getting the last product sources',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
