import { connect } from 'react-redux';
import LastProductState from '../../../../components/features/lastProductState/LastProductState';

import { getLastProductSources, getProductsStates, getStockCategories } from 'core/slices/productsStates';
import { getStoreCategories } from 'core/slices/store';
import { getUserStores, getUserSuppliers } from 'core/slices/users';

import {
  categories,
  getSelectedClient,
  getUserStoresAllList,
  isLoadingCategories,
  isLoadingLastProductSources,
  isLoadingProductsStates,
  isLoadingStockCategories,
  isLoadingUserStores,
  isLoadingUserSuppliers,
  lastProductSources,
  productsStates,
  stockCategories,
  userChains,
  userSuppliers
} from 'core/selectors';

export default connect(state => ({
  isLoadingUserStores: isLoadingUserStores(state),
  selectedClient: getSelectedClient(state),
  userChains: userChains(state),
  userStores: getUserStoresAllList(state),
  categories: categories(state),
  isLoadingCategories: isLoadingCategories(state),
  suppliers: userSuppliers(state),
  isLoadingSuppliers: isLoadingUserSuppliers(state),
  isLoadingProductsStates: isLoadingProductsStates(state),
  productsStates: productsStates(state),
  isLoadingStockCategories: isLoadingStockCategories(state),
  stockCategories: stockCategories(state),
  isLoadingLastProductSources: isLoadingLastProductSources(state),
  lastProductSources: lastProductSources(state),
}), {
  getUserStores,
  getStoreCategories,
  getUserSuppliers,
  getProductsStates,
  getStockCategories,
  getLastProductSources
})(LastProductState);
