import { Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";

import { DataGrid } from "@zippeditoolsjs/table";

const getColumnWidth = () => {
  const windowWidth = window.innerWidth;
  return (0.9 * windowWidth) / 8;
}

const formatColumns = (t) => {
  const dynamicWidth = getColumnWidth();
  const columns = [
    {
      field: 'item',
      headerName: t('cws_app.general.Item', 'Item'),
      align: 'left',
      width: dynamicWidth - 30,
    },
    {
      field: 'store',
      headerName: t('cws_app.general.Store', 'Store'),
      align: 'left',
      width: dynamicWidth - 40,
    },
    {
      field: 'stockCategory',
      headerName: t('cws_app.general.Stock_category', 'Stock category'),
      align: 'left',
      width: dynamicWidth,
      needTranslate: true,
    },
    {
      field: 'stock',
      headerName: t('cws_app.general.Referential_stock', 'Referential stock'),
      align: 'center',
      width: dynamicWidth - 40,
    },
    {
      field: 'available',
      headerName: t('cws_app.general.Available', 'Available'),
      align: 'center',
      width: dynamicWidth - 30,
      needTranslate: true,
    },
    {
      field: 'source',
      headerName: t('cws_app.general.Source', 'Source'),
      align: 'center',
      width: dynamicWidth - 40,
      needTranslate: true,
    },
    {
      field: 'description',
      headerName: t('cws_app.general.Description', 'Description'),
      align: 'center',
      width: dynamicWidth,
    },
    {
      field: 'consecutiveAvailableDays',
      headerName: t('cws_app.general.Consecutive_available_days', 'Consecutive available days'),
      align: 'center',
      width: dynamicWidth,
    },
    {
      field: 'consecutiveStockCategoryDays',
      headerName: t('cws_app.general.Consecutive_stock_category_days', 'Consecutive stock category days'),
      align: 'center',
      width: dynamicWidth,
    }
  ];

  return columns;
};

export default function DataTable(props) {
  const {
    t,
    productsStates,
    isLoadingProductsStates
  } = props;

  const [columns, setColumns] = useState([]);
  const [parsedProductsStates, setParsedProductsStates] = useState([]);

  // Reset columns when theme changes (because the icons don't change the color when the theme changes)
  useEffect(() => {
    setColumns(formatColumns(t));
  }, [t]);

  useEffect(() => {
    if (productsStates && productsStates.length > 0) {
      const translatedStates = productsStates.map(state => {
        const translatedState = { ...state };
        columns.forEach(column => {
          if (column.needTranslate && state[column.field]) {
            translatedState[column.field] = t(`cws_app.general.${state[column.field]}`, state[column.field]);
          }
        });
        return translatedState;
      });
      setParsedProductsStates(translatedStates);
    }
  }, [productsStates, columns, t]);

  return (
    <>
      <StyledPaper>
        <DataGrid
          getRowId={row => row.index}
          rows={parsedProductsStates}
          columns={columns}
          disableExportExcel={false}
          exportTranslations={{
            'excel': t('cws_app.general.export_xls', 'Export Excel'),
            'json': t('cws_app.general.export_json', 'Export JSON')
          }}
          localeText={{
            toolbarFilters: t('cws_app.general.filters', 'Filters'),
            columnMenuSortAsc: t('cws_app.general.sort_asc', 'Sort by ASC'),
            columnMenuSortDesc: t('cws_app.general.sort_desc', 'Sort by DESC'),
            toolbarExport: t('cws_app.general.export', 'Export'),
            toolbarQuickFilterPlaceholder: t('cws_app.general.search', 'Search'),
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 7,
              },
            },
          }}
          autoreset={false}
          pageSizeOptions={[7]}
          disableColumnSelector={true}
          disableRowSelect={true}
          loading={isLoadingProductsStates}
        />
      </StyledPaper>
    </>
  )
}

const StyledPaper = styled(props => {
  return <Paper elevation={1} {...props} />;
})(({ theme }) => {
  return {
    width: '100%',
    background:
      theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.primary.main
          : theme.palette.paper.main,
      color: 'white',
    },
    '& .MuiDataGrid-cell': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-columnsPanel': {
      background:
        theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
      color: theme.palette.type === 'light' ? 'black' : 'white',
    },
    '& .MuiDataGrid-menuIconButton': {
      opacity: 1,
      color: 'white',
    },
    '& .MuiDataGrid-sortIcon': {
      color: 'white',
    },
  };
});
